body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.card-reveal{
  color: #ffffff;
  background-color: rgba(30,30,30,0.7) !important;
}

table.highlight>tbody>tr:hover{
  background-color: rgba(238,110,115,0.6) !important;
}

td, th{
  padding: 5px 5px !important;
}

th {
  color: rgba(238,110,115,1.0) !important;
}